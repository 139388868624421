const hotelDayPassesByLocation = [
  {
    title: 'Top Locations',
    allUrl: 'https://www.resortpass.com/hotel-day-passes',
    allText: 'All Locations',
    data: [
      {
        name: 'Orlando, FL',
        url: 'https://www.resortpass.com/hotel-day-passes/Orlando-36',
      },
      {
        name: 'Miami, FL',
        url: 'https://www.resortpass.com/hotel-day-passes/Miami-14',
      },
      {
        name: 'Phoenix, AZ',
        url: 'https://www.resortpass.com/hotel-day-passes/Phoenix-12',
      },
      {
        name: 'San Diego, CA',
        url: 'https://www.resortpass.com/hotel-day-passes/San-Diego-3',
      },
      {
        name: 'San Juan, PR',
        url: 'https://www.resortpass.com/hotel-day-passes/San-Juan-903',
      },
      {
        name: 'Maui, HI',
        url: 'https://www.resortpass.com/hotel-day-passes/Maui-502',
      },
      {
        name: 'Tampa, FL',
        url: 'https://www.resortpass.com/hotel-day-passes/Tampa-69',
      },
      {
        name: 'Houston, TX',
        url: 'https://www.resortpass.com/hotel-day-passes/Houston-19',
      },
      {
        name: 'Honolulu, HI',
        url: 'https://www.resortpass.com/hotel-day-passes/Honolulu-35',
      },
      {
        name: 'Fort Lauderdale, FL',
        url: 'https://www.resortpass.com/hotel-day-passes/Fort-Lauderdale-15',
      },
    ],
  },
  {
    title: 'Top Hotels',
    allUrl: 'https://www.resortpass.com/browse-hotels',
    allText: 'All Hotels',
    data: [
      {
        name: 'The Don CeSar',
        url: 'https://www.resortpass.com/hotels/the-don-cesar',
      },
      {
        name: 'Marriott Marquis Houston',
        url: 'https://www.resortpass.com/hotels/marriott-marquis-houston',
      },
      {
        name: 'Margaritaville Ft. Myers',
        url: 'https://www.resortpass.com/hotels/margaritaville-beach-resort-fort-myers-beach',
      },
      {
        name: 'Baha Bay Waterpark',
        url: 'https://www.resortpass.com/hotels/baha-bay-waterpark',
      },
      {
        name: 'Hilton Anatole',
        url: 'https://www.resortpass.com/hotels/hilton-anatole',
      },
      {
        name: 'Fontainebleau Miami Beach',
        url: 'https://www.resortpass.com/hotels/fontainebleau-miami-beach',
      },
      {
        name: 'JW Marriott San Antonio',
        url: 'hhttps://www.resortpass.com/hotels/jw-marriott-san-antonio-hill-country-resort-and-spa',
      },
      {
        name: 'Margaritaville Hollywood Beach',
        url: 'https://www.resortpass.com/hotels/margaritaville-hollywood-beach-resort',
      },
      {
        name: 'Island Waterpark at Showboat',
        url: 'https://www.resortpass.com/hotels/island-waterpark-at-showboat',
      },
      {
        name: 'Grand Hyatt Kauai Resort & Spa',
        url: 'https://www.resortpass.com/hotels/grand-hyatt-kauai-resort-and-spa',
      },
    ],
  },
  {
    title: 'The Blog Posts',
    allUrl: 'https://www.resortpass.com/blog',
    allText: 'All Blog Posts',
    data: [
      {
        name: 'The 8 Best Rooftop Pools in NYC ',
        url: 'https://www.resortpass.com/blog/best-rooftop-pool-nyc/',
      },
      {
        name: '11 of the Best pool Resorts in the U.S.',
        url: 'https://www.resortpass.com/blog/best-pool-resorts-usa/',
      },
      {
        name: 'The 7 Best Beach Vacations in the U.S.',
        url: 'https://www.resortpass.com/blog/best-beach-vacations-in-the-us/',
      },
      {
        name: '10 Best Relaxing Vacation Spots in the US',
        url: 'https://www.resortpass.com/blog/relaxing-vacation-spots-us/',
      },
      {
        name: 'The 5 Best Spas in Los Angeles: Sanctuary for Your Soul',
        url: 'https://www.resortpass.com/blog/best-spas-la/',
      },
      {
        name: 'The Top 5 Water Parks in Florida',
        url: 'https://www.resortpass.com/blog/water-parks-florida/',
      },
      {
        name: 'The 8 Best Water Parks in California',
        url: 'https://www.resortpass.com/blog/water-parks-in-california/',
      },
      {
        name: 'The 9 Best Spas in Miami',
        url: 'https://www.resortpass.com/blog/the-9-best-spas-in-miami/',
      },
      {
        name: '10 Best Hotel Pools in Florida',
        url: 'https://www.resortpass.com/blog/best-hotel-pools-in-florida/',
      },
      {
        name: 'The 15 Best Pools in Miami',
        url: 'https://www.resortpass.com/blog/pools-in-miami/',
      },
    ],
  },
];

export default hotelDayPassesByLocation;
