import Link from 'next/link';
import React, { useContext, useMemo } from 'react';
import { SUMMER_WEEKDAYS_SECTION_CLICKED } from '@constants/amplitudeEvents';
import { store } from '@context/store';
import useIsMobile from '@hooks/useIsMobile';
import { useEvents } from '@events/EventsProvider';
import RoutingPath from '@constants/routingPath';
import ButtonPrimary from '@components/common/ButtonPrimary';
import ImageFallback from '@components/common/Image/ImageFallback';
import { getCdnImageUrl } from '@utils/cloudflare';

type PromotionCardProps = {
  title: string;
  body: string;
  image: string;
};

export default function PromotionCard({ title, body, image }: PromotionCardProps) {
  const globalState = useContext(store);
  const { state } = globalState;
  const { userLocation } = state;
  const isMobile = useIsMobile();
  const { track } = useEvents();

  const spaSRPLink = useMemo(() => {
    if (userLocation) {
      const { name, id } = userLocation;
      const cityName = name.split(',')[0];
      return `${RoutingPath.SRP}/${cityName}-${id}`;
    }

    return `${RoutingPath.SRP}/Los-Angeles-4`;
  }, [userLocation]);

  const handleSpaModuleClick = () => {
    track(SUMMER_WEEKDAYS_SECTION_CLICKED, {
      user_location: userLocation ? userLocation.name : null,
    });
  };

  return (
    <Link href={spaSRPLink}>
      <a
        tabIndex={0}
        role="button"
        className="cursor-pointer"
        onClick={handleSpaModuleClick}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === 'Space') {
            handleSpaModuleClick();
          }
        }}
      >
        <div className="px-6">
          <div className="bg-rp-navy flex flex-col items-center justify-center mt-20 pb-10 pt-172px relative rounded-xl w-full d:flex-row d:pb-50px d:pt-58px d:h-370">
            <div
              className="absolute -top-32px d:relative d:top-0"
              style={{ width: isMobile ? '233px' : '544px', height: isMobile ? '180px' : '420px' }}
            >
              <ImageFallback
                src={getCdnImageUrl(
                  544,
                  `home-v2/${image}`,
                  'https://static-assets.resortpass.com/assets/',
                )}
                loading="lazy"
                layout="fill"
                className="rounded-xl"
                alt="women in steamy sauna"
              />
            </div>

            <div className="flex flex-col max-w-549 ml-0 space-y-6 px-6 text-white d:px-0 d:ml-11">
              <h2 className="font-rp-gelica-light leading-normal text-28 max-w-295 d:text-40px d:w-full d:max-w-none">
                {title}
              </h2>

              <p className="font-rp-pn-regular leading-6 text-base">{body}</p>

              <ButtonPrimary onClick={() => null} classes="w-full d:max-w-200">
                Search Near You
              </ButtonPrimary>
            </div>
          </div>
        </div>
      </a>
    </Link>
  );
}
