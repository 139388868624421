import React from 'react';
import Link from 'next/link';
import hotelDayPassesByLocation from '@constants/hotelDayPassesBylocation';

export default function StaticDayPassesByLocation() {
  return (
    <div
      className="bg-gradient-to-b from-white to-rp-light-gray-2 pb-4"
      data-testid="day-passes-by-location-section"
    >
      <div className="h-0.5 w-16 bg-rp-primary mx-auto mb-2 mt-10 d:w-20 d:mt-20" />
      <div className="mx-auto text-center text-3xl text-rp-primary-black-medium tracking-tight pt-5 pb-10 d:text-4xl d:pb-0 d:mb-10">
        <span className="font-rp-cera-thin">Hotel </span>
        <span className="font-rp-cera-medium">Day Passes </span>
        <span className="font-rp-cera-thin">by Location </span>
      </div>

      <div className="flex gap-20 justify-center px-7 d:max-w-8xl w-full m-auto flex-col d:flex-row mx-auto">
        {hotelDayPassesByLocation?.map((section) => (
          <div className="flex flex-col gap-4" key={section.title}>
            <h2 className="font-rp-pn-semi-bold text-lg text-rp-primary-black-bold">
              {section.title}
            </h2>
            <ul className="flex flex-col list-none">
              {section.data.map((data) => (
                <li key={data.url} data-testid={data.url}>
                  <Link href={data.url}>
                    <a className="text-rp-primary font-rp-pn-light text-15" target="_blank">
                      {data.name}
                    </a>
                  </Link>
                </li>
              ))}
            </ul>
            <Link href={section.allUrl} key={section.allUrl} data-testid={section.allUrl}>
              <a className="text-rp-primary font-rp-pn-light text-15" target="_blank">
                {section.allText}
              </a>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}
