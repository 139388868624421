import React from 'react';
import Link from 'next/link';
import { becomeHotelPartnerClickHomePage } from '@events/HomePage';
import ButtonPrimaryTransparent from '@common/ButtonPrimaryTransparent';

export default function HotelPartner() {
  return (
    <div className="pb-32 text-center" data-testid="hotel-partner-section">
      <div className="font-rp-cera-thin text-3xl tracking-tight mb-11 text-rp-primary-black-medium mx-auto max-w-2xl w-10/12 d:text-4xl">
        Are you a hotel manager interested in partnering with us?
      </div>
      <Link href="https://partners.resortpass.com/" passHref>
        <div className="h-5 mx-auto">
          <ButtonPrimaryTransparent
            onClick={() => {
              becomeHotelPartnerClickHomePage();
            }}
            classes="px-16 py-5 text-lg text-rp-primary-light font-rp-pn-regular"
          >
            Become a Hotel Partner
          </ButtonPrimaryTransparent>
        </div>
      </Link>
    </div>
  );
}
