const exploreLocationsData = [
  {
    state: 'CALIFORNIA',
    href: 'results?state=CA',
    imgSrc: '/home-v2/explore/california@2x.jpg',
    alt: 'View of clear skys and palm trees from a resort in California',
  },
  {
    state: 'FLORIDA',
    href: 'results?state=FL',
    imgSrc: '/home-v2/explore/florida@2x.jpg',
    alt: 'Private beach with lounge chairs and clear aqua water at a resort in Florida',
  },
  {
    state: 'MEXICO',
    href: 'hotel-day-passes?country_name=Mexico',
    imgSrc: '/home-v2/explore/Mexico.jpg',
    alt: 'Mexico',
  },
  {
    state: 'ARIZONA',
    href: 'results?state=AZ',
    imgSrc: '/home-v2/explore/arizona-new@2x.jpg',
    alt: 'Orange sunset over desert seen from a resort in Arizona',
  },
  {
    state: 'HAWAII',
    href: 'results?state=HI',
    imgSrc: '/home-v2/explore/hawaii@2x.jpg',
    alt: 'View of mountains from a resort in Hawaii',
  },
  {
    state: 'BAHAMAS',
    href: 'hotel-day-passes?country_name=The+Bahamas',
    imgSrc: '/home-v2/explore/Caribbean.jpg',
    alt: 'Bahamas',
  },
];

export default exploreLocationsData;
