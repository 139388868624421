import React from 'react';
import Link from 'next/link';
import ImageFallback from '@components/common/Image/ImageFallback';
import { giftCardLearnMoreClick } from '@events/HomePage';
import RoutingPath from '@constants/routingPath';
import giftCard from '@assets/images/gift-card-holiday.svg';

export default function GiftCard() {
  return (
    <div data-testid="gift-card-section" className="px-4">
      <div className="w-full max-w-8xl bg-rp-light-gray-2 d:mx-auto rounded-lg mb-20 px-6 text-center d:text-left">
        <div className="flex flex-col mx-auto justify-between max-w-5xl items-center d:flex-row d:items-stretch">
          <div className="pt-10 d:pt-24 d:pb-20">
            <div className="font-rp-cera-bold text-4xl mb-5 bg-orange-gradient bg-clip-text text-transparent d:text-5xl">
              Give a daycation
            </div>
            <div className="max-w-lg font-rp-pn-regular opacity-70 text-rp-primary-black-medium mb-7 tracking-tight">
              <div>Know someone who could use some pampering and relaxation in their life? </div>
              <div>A day of luxury is the perfect gift.</div>
            </div>
            <div className="w-56 hidden d:block">
              <Link href="/giftcards">
                <a
                  role="link"
                  tabIndex={0}
                  aria-label="Learn more information about the Gift Cards"
                  className="w-full flex justify-center items-center bg-rp-primary hover:bg-rp-primary-light active:bg-rp-primary-dark py-4 rounded-xl text-white font-rp-pn-semi-bold tracking-wide"
                  onClick={() => {
                    giftCardLearnMoreClick();
                  }}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      giftCardLearnMoreClick();
                    }
                  }}
                >
                  Learn More<span className="hidden"> about ResortPass Gift Cards</span>
                </a>
              </Link>
            </div>
          </div>
          <div className="relative h-56 w-72 mb-10 d:w-2/5 d:h-auto d:m-0">
            <ImageFallback
              src={giftCard}
              layout="fill"
              objectPosition="cover"
              alt="Illustration of a Gift Card"
            />
          </div>
          <div className="w-56 mb-10 d:hidden">
            <Link href={RoutingPath.GIFT_CARDS}>
              <a
                role="link"
                tabIndex={0}
                className="w-full flex justify-center items-center bg-rp-primary hover:bg-rp-primary-light active:bg-rp-primary-dark py-4 rounded-xl text-white font-rp-pn-semi-bold tracking-wide"
                onClick={() => {
                  giftCardLearnMoreClick();
                }}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    giftCardLearnMoreClick();
                  }
                }}
              >
                Learn More<span className="hidden"> about ResortPass Gift Cards</span>
              </a>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
