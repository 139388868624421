import { useMemo, useEffect, useState, useRef } from 'react';
import { useEvents } from '@events/EventsProvider';
import {
  AMPLITUDE_EXPERIMENT_CONTROL_NAME,
  AMPLITUDE_EXPERIMENT_VARIANT_NAME,
  AMPLITUDE_EXPERIMENT_VARIANT_NAME_2,
} from '@constants/amplitudeExperiments';

export default function useAmplitudeExperiment<T>(
  experimentName: string,
): typeof AMPLITUDE_EXPERIMENT_CONTROL_NAME | T {
  const { amplitudeExperiment } = useEvents();

  return useMemo(() => {
    if (amplitudeExperiment) {
      const variantType = amplitudeExperiment.variant(experimentName).value as T;
      return variantType || AMPLITUDE_EXPERIMENT_CONTROL_NAME;
    }
    return AMPLITUDE_EXPERIMENT_CONTROL_NAME;
  }, [amplitudeExperiment, experimentName]);
}

export function useAmplitudeExperimentAB(experimentName: string) {
  return useAmplitudeExperiment<typeof AMPLITUDE_EXPERIMENT_VARIANT_NAME>(experimentName);
}

export function useAmplitudeExperimentABC(experimentName: string) {
  return useAmplitudeExperiment<
    typeof AMPLITUDE_EXPERIMENT_VARIANT_NAME | typeof AMPLITUDE_EXPERIMENT_VARIANT_NAME_2
  >(experimentName);
}

export function useAmplitudeExperimentWithTimeout<T>(experimentName: string, ms = 1000): string {
  const hasResolved = useRef<boolean>(false);
  const { amplitudeExperiment } = useEvents();
  const [experiment, setExperiment] = useState<string>('');

  const timeout = setTimeout(() => {
    if (!hasResolved.current) {
      hasResolved.current = true;
      setExperiment(AMPLITUDE_EXPERIMENT_CONTROL_NAME);
    }
    clearTimeout(timeout);
  }, ms);

  // Amplitude Loaded Condition
  useEffect(() => {
    if (amplitudeExperiment) {
      if (!hasResolved.current) {
        hasResolved.current = true;
        const variantType =
          (amplitudeExperiment.variant(experimentName).value as T) ||
          AMPLITUDE_EXPERIMENT_CONTROL_NAME;
        setExperiment(variantType as string);
      }
    }
  }, [amplitudeExperiment, experimentName]);
  return experiment;
}
