import React from 'react';
import Link from 'next/link';
import ImageFallback from '@components/common/Image/ImageFallback';
import { exploreLocationThumbnailClick } from '@events/HomePage';
import { getCdnImageUrl } from '../../utils/cloudflare';
import exploreLocationsData from './ExploreLocationsData';

const imageStyle = 'w-full max-w-full h-auto mb-6 relative cursor-pointer d:w-32% relative';
const textContainerStyle = 'absolute text-center w-full top-1/3 mt-2.5 z-10';
const exploreTextStyle = 'text-white text-xs leading-3 font-rp-pn-semi-bold tracking-widest';
const stateTextStyle = 'text-white text-3xl leading-10 font-rp-pn-semi-bold tracking-widest';

export default function ExploreLocations() {
  return (
    <div className="flex flex-col w-full mx-auto pt-4" data-testid="explore-more-locations">
      <div className="h-0.5 w-16 bg-rp-primary mx-auto mb-2 d:w-20" />
      <div
        className="mx-auto text-center text-3xl text-rp-primary-black-medium tracking-tight py-5 d:text-4xl d:pb-0"
        role="heading"
        aria-level={1}
      >
        <span className="font-rp-cera-medium">Explore </span>
        <span className="font-rp-cera-thin">Locations</span>
      </div>

      <div className="flex px-6 flex-wrap justify-between items-center w-full mx-auto mt-10 max-w-8xl mb-20">
        {exploreLocationsData.map((data) => (
          <Link key={data.href} href={data.href}>
            <a
              role="link"
              tabIndex={0}
              className={imageStyle}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  exploreLocationThumbnailClick(data.state);
                }
              }}
              onClick={() => {
                exploreLocationThumbnailClick(data.state);
              }}
            >
              <div role="heading" aria-level={2} className={textContainerStyle}>
                <div className={exploreTextStyle}>EXPLORE</div>
                <div className={stateTextStyle}>{data.state}</div>
              </div>

              <ImageFallback
                src={getCdnImageUrl(
                  500,
                  data.imgSrc,
                  'https://static-assets.resortpass.com/assets',
                  75,
                )}
                blurDataUrl={getCdnImageUrl(
                  50,
                  data.imgSrc,
                  'https://static-assets.resortpass.com/assets',
                  10,
                )}
                alt={data.alt}
                loading="lazy"
                width={834}
                height={516}
                layout="responsive"
                className="rounded-lg"
              />
            </a>
          </Link>
        ))}
      </div>
    </div>
  );
}
