/* eslint-disable react/jsx-no-useless-fragment */
import React, { useContext, useEffect, useState } from 'react';
import { getPopularHotels } from '@utils/services';
import { useCookies } from 'react-cookie';
import useSessionStorage from '@hooks/useSessionStorage';
import sessionStorageKeys from '@constants/sessionStorageKeys';
import { homePageView } from '@events/HomePage';
import HomePage from '@components/HomePage/HomePage';
import { PopularHotel } from '@customTypes/popular-hotel-data';
import { store } from '@context/store';
import Layout from '@components/Layout/Layout';
import useAmplitudeExperiment from '@hooks/useAmplitudeExperiment';
import {
  AMPLITUDE_EXPERIMENT_CONTROL_NAME,
  AMPLITUDE_EXPERIMENT_VARIANT_NAME,
} from '@constants/amplitudeExperiments';
import actionTypes from '@context/actionTypes';

type IndexPageProps = {
  popularHotels: PopularHotel[];
};

const getPopularHotelsData = async () =>
  getPopularHotels()
    .then((response) => ({
      showPopularHotels: true,
      popularHotels: response?.hotels,
    }))
    .catch(() => ({
      showPopularHotels: false,
      popularHotels: null,
    }));

export async function getStaticProps() {
  const popularHotels = await getPopularHotelsData();

  return {
    props: {
      ...popularHotels,
    },
  };
}

export default function Home({ popularHotels }: IndexPageProps) {
  const [homePageViewFired, setHomePageViewFired] = useState<boolean>(false);

  const { getItem } = useSessionStorage();
  const date = getItem(sessionStorageKeys.SMART_CALENDAR_DATE);
  const sessionID = getItem(sessionStorageKeys.SESSION_ID);
  const [cookies] = useCookies();
  const PRODUCT_TILES = useAmplitudeExperiment<
    typeof AMPLITUDE_EXPERIMENT_VARIANT_NAME | typeof AMPLITUDE_EXPERIMENT_CONTROL_NAME
  >('product-tiles');

  const {
    state: { userCityAndState },
    dispatch,
  } = useContext(store);

  useEffect(() => {
    dispatch({
      type: actionTypes.SET_PRODUCT_TILES_AB_TEST,
      payload: PRODUCT_TILES,
    });
  }, [PRODUCT_TILES, dispatch]);

  // GA4 Page View Event
  useEffect(() => {
    if (!homePageViewFired && sessionID) {
      homePageView(cookies.userInformation, sessionID, date);
      setHomePageViewFired(true);
    }
  }, [cookies.userInformation, date, homePageViewFired, sessionID]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return <HomePage popularHotels={popularHotels} userCityAndState={userCityAndState} />;
}

Home.getLayout = (page: React.ReactElement, pageProps: IndexPageProps) => (
  <Layout searchBarEnabled={false} popularHotels={pageProps?.popularHotels}>
    {page}
  </Layout>
);
